"use client";

import { createContext, useState, useContext,useEffect } from "react";
const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
  const [recentChat, setRecentChat] = useState(null);
  const [selectedThread, setSelectedThread] = useState<string>(null);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [currentAssistant, setCurrentAssistant] = useState<any>(null);
  const [assistantSelectedForEdit, setAssistantSelectedForEdit] = useState<any>(null);
  const [globalActiveTab, setGlobalActiveTab] = useState<string>('');
  return (
    <DataContext.Provider value={{ recentChat, setRecentChat, selectedThread, setSelectedThread,currentUser,setCurrentUser, currentAssistant, setCurrentAssistant, assistantSelectedForEdit, setAssistantSelectedForEdit, globalActiveTab, setGlobalActiveTab }}>
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
