'use client'
import './globals.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import MainComponent from "@/components/MainComponent";
import { Toaster } from 'react-hot-toast';
import AuthProvider from '../context/AuthProvider';
import { DataProvider } from '../context/dataContext';
import Script from 'next/script';
import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import * as gtag from '../lib/gtag';

const META_PIXEL_ID = process.env.NEXT_PUBLIC_META_PIXEL_ID || '';
const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID || '';

export default function RootLayout({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();

  useEffect(() => {
    // Meta Pixel Initialization
    window.fbq = function () {
      window.fbq.callMethod ? window.fbq.callMethod.apply(window.fbq, arguments) : window.fbq.queue.push(arguments);
    };
    if (!window._fbq) window._fbq = window.fbq;
    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = "2.0";
    window.fbq.queue = [];
    window.fbq("init", META_PIXEL_ID);
    window.fbq("track", "PageView");

    // Google Analytics Pageview Tracking
    const handleRouteChange = (url: string) => {
      const fullUrl = new URL(url, window.location.origin);
      gtag.pageview(fullUrl);
    };

    handleRouteChange(pathname);
  }, [pathname]);

  return (
    <html lang="en">
      <head>
      <title>
        {process.env.NEXT_PUBLIC_DOMAIN_NAME}
        </title> 
        {/* Meta Pixel Script */}
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${META_PIXEL_ID}');
              fbq('track', 'PageView');
            `,
          }}
        />
        {/* Google Analytics Script */}
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
        />
        <Script
          id="google-analytics"
          strategy="afterInteractive"
        >
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_MEASUREMENT_ID}', {
              page_path: window.location.pathname,
            });
          `}
        </Script>
      </head>
      <body className={``} suppressHydrationWarning={true}>
        <AuthProvider>
          <DataProvider>
            <Toaster />
            <MainComponent children={children} />
          </DataProvider>
        </AuthProvider>
      </body>
    </html>
  );
}