// lib/gtag.ts
export const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID || '';

// Function to send pageview event
export const pageview = (url: URL) => {
  window.gtag('config', GA_MEASUREMENT_ID, {
    page_path: url,
  });
};

// Function to send custom events
export const event = ({ action, category, label, value }: { action: string; category: string; label: string; value: number }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};